.btn {
    /* outros estilos que você já pode ter */
    border-radius: 10px; /* Isso criará bordas arredondadas nos botões */
    padding: 5px 10px; /* Ajuste para espaço interno, se necessário */
    margin: 5px; /* Espaço entre os botões */
    border: 1px solid #ccc; /* Borda para os botões */
    background-color: #f8f8f8; /* Cor de fundo */
    cursor: pointer; /* Cursor de mão ao passar o mouse sobre o botão */
}

.btn:hover {
    background-color: #e7e7e7; /* Cor de fundo ao passar o mouse sobre o botão */
}

.text-fs {
    font-size: 1.188rem !important;
}