p {
  text-align: justify;
}

pre code {
  color: #f4f9cf !important;
  background-color: #000000; /* Cor cinza claro */
  display: block;
  padding: 16px 30px 20px;
  overflow-x: auto;
}